body {
  background-color: #235673;
}

.app {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding-top: 80px;
  align-items: center;
  min-height: 100vh;
  color: #fff;
  font-family: 'Helvetica Neue', sans-serif;
}

.header {
  position: fixed;
  top: 0;

  width: 100%;
  height: 80px;

  background-color: #313131;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer,
.button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  background: linear-gradient(to top, rgb(231, 134, 73), rgba(0, 0, 0, 0));
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.logo {
  height: 50px;
  padding: 0 5px
}

.logo:hover {
  cursor: pointer;
}

.header-links {
  padding-right: 15px;
}

.container {
  max-width: 600px;
}

h1,
h2 {
  text-align: center;
  text-decoration: underline;
}

h1 {
  font-size: 3rem;
  margin-top: 2rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 3rem;
}

.fun-fact {
  text-align: center;
  font-size: 2rem;
  margin: 0 0.5em 2em 0.5em;
}

button {
  padding: 1rem 2rem;
  font-size: 1.5rem;
  border: none;
  border-radius: 5px;
  background-color: #277fa0;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: none;
}

button:hover {
  background-color: #0b3954;
  transform: translateY(2px);
}

a {
  color: #fff;
}

.email-link-wrapper {
  display: flex;
  justify-content: center;
}

.about {
  text-align: center;
}

.spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
    margin-top: 1rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .fun-fact {
    margin-bottom: 6em;
  }

  button {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
  }
}